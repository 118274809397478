import { render, staticRenderFns } from "./FQDNReferences.vue?vue&type=template&id=587f7744&scoped=true"
import script from "./FQDNReferences.vue?vue&type=script&lang=js"
export * from "./FQDNReferences.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "587f7744",
  null
  
)

export default component.exports