<template>
  <div class="fqdn_refs">
    <h1>{{ $t('views.dnsvs.fqdn_references.references_to_fqdn') }} '<span
      v-if="$route.params.fqdn === '$'">.</span><span
      v-if="$route.params.fqdn !== '$'">{{ $route.params.fqdn }}</span>'
    </h1>
    <FlatRecordTable :records="records" :fixed_fqdn="fqdn?fqdn.value:null"
                     :full_edit_fqdn_reducer="full_edit_fqdn_reducer"
                     :create_record_reducer="create_record_reducer"
                     :subnets="subnets" :fqdns="fqdns"
    ></FlatRecordTable>
  </div>
</template>

<script>
import RRService from '@/api-services/dns_fqdn.service'
import FlatRecordTable from '@/components/FlatRecordTable.vue'
import apiutil from '@/util/apiutil'

export default {
  name: 'FQDNReferences',
  data() {
    return {
      records: null,
      fqdn: null,
      fqdns: null,
      subnets: null,
      full_edit_fqdn_reducer: null,
      create_record_reducer: null
    }
  },
  components: { FlatRecordTable },
  watch: {
    async $route() {
      await this.fetchData()
    }
  },
  methods: {
    async fetchData() {
      this.records = null
      const response = await RRService.getRecordsByTargetFQDN(this.$store.state, this.$route.params.fqdn)
      this.fqdn = response.data.fqdn_list[0]
      this.fqdns = apiutil.dict_by_value_of_array(response.data.rec_fqdns, 'value')
      this.records = response.data.record_list
      this.subnets = response.data.ip_subnet_list
      const fqdnSelections = {}
      for (const t of response.data.fqdn_type_list) {
        let disp_name = t.description
        disp_name += ' ['
        disp_name += t.is_nonterminal ? this.$t('system.non_terminal') : this.$t('system.terminal')
        disp_name += ']'
        fqdnSelections[t.name] = { display_name: disp_name }
      }
      this.full_edit_fqdn_reducer = {
        type: fqdnSelections
      }
      const selections = {}
      for (const t of response.data.record_inttype_list) {
        selections[t.record_type] = { display_name: t.record_type }
      }
      this.create_record_reducer = {
        fqdn: false,
        type: selections
      }
    }
  },
  async created() {
    await this.fetchData()
  }
}
</script>

<style scoped>

</style>
